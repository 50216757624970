<template>
  <div class="auth-wrapper auth-v2 bg-white">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col
        lg="6"
        class="d-none d-lg-flex align-items-center"
        :style="`background-image: url(${sideImg});background-size: cover;background-position: 50%;border-radius: 0px 32px 32px 0px;`"
      >
        <div class="left--side__wrapper p-3">
          <div class="wrapper__side">
            <div>
              <img
                class="mb-1"
                src="@/assets/images/logo.png"
                alt="logo"
                width="95"
              />
              <hr class="w-25 m-0" style="border: 1px solid #e79242" />
              <div class="my-3">
                <h6 class="size14 fw-bold-400" style="line-height: 23px">
                  Khusus di desain untuk manajemen Toko Bangunan. <br />
                  Praktis, mudah dan lengkap.
                </h6>
              </div>
            </div>
            <div>
              <h5 class="size14 fw-bold-400 mb-1">Sudah punya akun ?</h5>
              <h5
                class="size16 fw-bold-600"
                @click="$router.push({ name: 'login' })"
                role="button"
              >
                Masuk Sekarang
              </h5>
            </div>
          </div>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="6" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="10" class="px-xl-2 mx-auto">
          <b-card-title
            title-tag="h2"
            class="fw-bold-800 mb-1 text-dark size20"
          >
            👋🏼 Halo, Selamat Datang
          </b-card-title>
          <b-card-text class="mb-2 text-dark text-darken-4 size14 mb-md-5 mb-0">
            Coba Gratis dan pelajari fitur yang di sediakan
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
              <!-- hp -->
              <!-- <b-form-group class="custom__form--input mb-2">
                <label for="hp">No Hp <span class="text-danger">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="No Hp"
                  rules="required|regex:^([0-9]+)$"
                >
                  <b-form-input
                    id="hp"
                    v-model="handphone"
                    name="login-no-hp"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Masukan Nomor Handphone"
                    class="custom__input"
                    autofocus
                    
                    onkeydown="return event.keyCode !== 69"
                  />
                  <small class="text-danger pt-1">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->
              <b-row class="align-items-end">
                <b-col cols="6" lg="6" xl="5">
                  <b-form-group class="custom__form--input mb-2">
                    <label for="handphone"
                      >No. Handphone <span class="text-danger">*</span></label
                    >
                    <v-select
                      v-model="selectedCountry"
                      :label="currentLabel"
                      :options="countryList"
                      :clearable="false"
                      @keyup.stop.native="searchCountry"
                    >
                      <template
                        #selected-option="{ country_flag, dial_code_preview }"
                      >
                        <div class="d-flex align-items-center" style="gap: 6px">
                          <img
                            :src="country_flag"
                            :alt="dial_code_preview"
                            style="width: 24px; height: 24px"
                          />
                          <p class="size12 mb-0">({{ dial_code_preview }})</p>
                        </div>
                      </template>
                      <template #option="{ country_flag, dial_code_preview }">
                        <div class="d-flex align-items-center" style="gap: 8px">
                          <img
                            :src="country_flag"
                            :alt="dial_code_preview"
                            style="width: 24px; height: 24px"
                          />
                          <p class="mb-0">{{ dial_code_preview }}</p>
                        </div>
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="6" xl="7">
                  <b-form-group
                    class="custom__form--input mb-2 position-relative"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="No. Handphone"
                      rules="required"
                    >
                      <b-form-input
                        id="handphone"
                        type="number"
                        v-model="formattedPhone"
                        name="login-email"
                        class="custom__input w-100"
                        :state="errors.length > 0 ? false : null"
                        placeholder="No. Handphone"
                        autofocus
                      />
                      <small
                        class="text-danger pt-1 position-absolute"
                        style="left: 0; bottom: -18px"
                        >{{ errors[0] }}</small
                      >
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- nama -->
              <b-form-group class="custom__form--input mb-2">
                <label for="nama-user"
                  >Nama Pengguna <span class="text-danger">*</span></label
                >
                <validation-provider
                  #default="{ errors }"
                  name="Nama Pengguna"
                  rules="required"
                >
                  <b-form-input
                    id="nama-user"
                    v-model="formPayload.operator_name"
                    name="login-name"
                    class="custom__input"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Masukan Nama"
                  />
                  <small class="text-danger pt-1">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- toko -->
              <b-form-group class="custom__form--input mb-2">
                <label for="nama-toko"
                  >Nama Toko <span class="text-danger">*</span></label
                >
                <validation-provider
                  #default="{ errors }"
                  name="Nama Toko"
                  rules="required"
                >
                  <b-form-input
                    id="nama-toko"
                    v-model="formPayload.merchant_name"
                    name="login-name-toko"
                    class="custom__input"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Masukan Nama Toko"
                  />
                  <small class="text-danger pt-1">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group class="custom__form--input mb-2">
                <label for="login-password"
                  >Password <span class="text-danger">*</span></label
                >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="formPayload.password"
                      :type="passwordFieldType"
                      class="form-control-merge custom__input"
                      :state="errors.length > 0 ? false : null"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-checkbox
                v-model="terms_condition"
                class="custom-control-success mb-1"
              >
                Saya setuju dengan
                <a href="https://toqoo.id/term-and-condition" target="_blank"
                  >Syarat dan Ketentuan</a
                >
                Toqoo
              </b-form-checkbox>
              <b-form-checkbox
                v-model="agreement"
                class="custom-control-success mb-3"
              >
                Saya setuju dengan
                <a href="https://toqoo.id/agreement" target="_blank"
                  >Kesepakatan Bersama</a
                >
                Toqoo
              </b-form-checkbox>

              <!-- submit buttons -->
              <b-button
                id="button--register"
                type="submit"
                variant="secondary"
                block
                :disabled="isLoading || !terms_condition || !agreement"
                class="size14 py-1 border-8 d-flex justify-content-center align-items-center"
                @click="validationForm"
              >
                <div
                  v-if="isLoading"
                  class="d-flex justify-content-center mr-1"
                >
                  <b-spinner small label="Loading..." />
                </div>
                Coba Gratis Sekarang
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Jika ada kendala terkait login bisa </span>
            <a
              class="text-dark text-darken-6"
              :href="'https://wa.me/6281807070900'"
              target="_blank"
            >
              &nbsp;hubungi admin
            </a>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import _ from "lodash";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormCheckbox,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BCardText,
  BCardTitle,
  BForm,
  BButton,
  BSpinner,
  BInputGroupAppend,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from "@/auth/jwt/useJwt";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BInputGroupAppend,
    BFormCheckbox,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      terms_condition: false,
      agreement: false,
      sideImg: require("@/assets/images/background.png"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      isLoading: false,
      handphone: "",
      selectedCountry: {
        country_code: "ID",
        country_flag: "https://flagsapi.com/ID/shiny/64.png",
        country_name: "Indonesia",
        dial_code_preview: "+62",
        dial_code_value: "62",
      },
      formPayload: {
        operator_phone: "",
        operator_phone_country: "",
        operator_name: "",
        merchant_name: "",
        password: "",
      },
      searchQuery: "",
      countryList: [],
    };
  },
  computed: {
    currentLabel() {
      return /^\d+$/.test(this.searchQuery)
        ? "dial_code_value"
        : "country_name";
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    dialCode() {
      const selectedCountry = this.countryList.find(
        (country) => country.country_code === this.selectedCountry?.country_code
      );
      return selectedCountry ? selectedCountry.dial_code_value : "62";
    },
    formattedPhone: {
      get() {
        const regex = new RegExp(`^${this.dialCode}`);
        return this.formPayload.operator_phone.replace(regex, "");
      },
      set(value) {
        if (value.startsWith("0")) {
          value = value.substring(1);
        }
        this.formPayload.operator_phone = this.dialCode + value;
      },
    },
  },
  watch: {
    // handphone(val) {
    //   const digit0 = String(val).charAt(0);
    //   const digit1 = Number(digit0);
    //   if (digit1 === 0) {
    //     // phone = phone.substring(1)
    //     this.formPayload.operator_phone = `62${val.substring(1)}`;
    //   } else {
    //     this.formPayload.operator_phone = val;
    //   }
    // },
  },
  mounted() {
    this.getCountry();
  },
  methods: {
    async validationForm() {
      this.formPayload.operator_phone_country =
        this.selectedCountry.country_code;
      this.$refs.loginValidation.validate().then(async (success) => {
        this.isLoading = true;
        if (success) {
          try {
            const response = await this.$http.post(
              "/register",
              this.formPayload
            );
            const userData = JSON.stringify(response.data.data.user);
            const userPermission = JSON.stringify(
              response.data.data.permission
            );

            useJwt.setToken(response.data.data.token);

            localStorage.setItem("userPermission", userPermission);
            localStorage.setItem("userData", userData);
            localStorage.setItem("temporary_token", response.data.data.token);

            localStorage.setItem(
              "operator_phone",
              this.formPayload.operator_phone
            );
            localStorage.setItem(
              "operator_phone_country",
              this.formPayload.operator_phone_country
            );
            localStorage.setItem("otp_type", "register");
            localStorage.setItem("expired_at", response.data.data.expired_at);
            // useJwt.setToken(response.data.data.token)
            this.$router.push("/otp");
            this.isLoading = false;
          } catch (error) {
            this.isLoading = false;
          }
        } else {
          this.isLoading = false;
        }
      });
    },
    searchCountry: _.debounce(function (e) {
      const search = e.target.value;
      this.searchQuery = search;
      this.getCountry(search);
    }, 300),
    getCountry(search) {
      const isDialCode = /^\d+$/.test(search);
      const params = isDialCode ? { search: search } : { search: search };

      this.$http
        .get("/country-phone-codes?per_page=500", { params })
        .then((response) => {
          this.countryList = response.data.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
.image__cover--login {
  border-radius: 0px 32px 32px 0px;
  object-fit: cover;
  height: 100vh;
}
.left--side__wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(21, 38, 61, 0.2);
  backdrop-filter: blur(44.3121px);
  border-radius: 20.257px;
  width: 420px;

  .wrapper__side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h1 {
      font-family: "Medula One", sans-serif !important;
      font-size: 50px;
    }

    h5,
    h6 {
      color: #fff;
      font-family: "Be Vietnam Pro", sans-serif !important;
    }
  }
}
</style>
